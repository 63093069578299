import React, { useEffect, useState } from 'react'
import { StgHeaderProps } from './types'
import { Column, Container, Row } from '@/components/base/gridview'
import Anchor from '@/components/base/anchor/Anchor'
import { useApp, useWidth, path, useSafari } from '@wap-client/core'
import Image from '@/components/base/image'
import Icon from '@/components/base/icon/Icon'
import button from '@/components/base/button'
import { pageService } from '@wap-client/services'
import { useRouter } from 'next/router'

const Header: React.FC<StgHeaderProps> = ({ logo, menu, mobilelogo }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isLanguageMenu, setIsLanguageMenu] = useState(false)
  const app = useApp()
  const width = useWidth()
  const router = useRouter()

  const { title: homeTitle, href: homeUrl } = app.settings.routes['homepage']

  const isOutsideMenu = (e: any) => {
    if (
      isLanguageMenu &&
      e.target.closest('.language-selection-button') === null
    ) {
      setIsLanguageMenu(false)
    }
  }
  useEffect(() => {
    const handleOutsideClick = (e: any) => {
      isOutsideMenu(e)
    }
    window.addEventListener('click', handleOutsideClick)
    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [isLanguageMenu])

  const handleMenuClose = () => {
    setIsMobileMenuOpen(false)
  }

  const openMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen)
  }

  const languageOpen = () => {
    setIsLanguageMenu(!isLanguageMenu)
  }

  const handlerRouterFooter = () => {
    const element = document.getElementById('footer')

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const handleLanguageSelection: (language: string) => void = (
    language: string
  ) => {
    pageService
      .findPath(app.environment, { path: app.page.path, language })
      .then((slug) => {
        router.push(path.app(app.environment, slug))
      })
      .catch(() => {
        const homepagePath = app.settings.homepage

        if (homepagePath) {
          pageService
            .findPath(app.environment, { path: homepagePath, language })
            .then((slug) => {
              router.push(path.app(app.environment, slug))
            })
        }
      })
  }

  useEffect(() => {
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'auto'
  }, [isMobileMenuOpen])

  return (
    <React.Fragment>
      <header className="stg-header">
        <Container size="extended">
          <Row>
            <Column>
              <Anchor href={homeUrl}>
                <Image className="stg-header-logo" {...logo} alt="" />
              </Anchor>
            </Column>
            <Column className="stg-header-menu">
              <div className="stg-header-menu-items">
                {width > 860 ? (
                  <ul>
                    {Array.isArray(menu) &&
                      menu?.map((menuItem, index) => {
                        if (menuItem?.href === '#footer') {
                          return (
                            <li
                              key={index}
                              onClick={handlerRouterFooter}
                              style={{ cursor: 'pointer' }}
                            >
                              <span>{menuItem?.title}</span>
                            </li>
                          )
                        } else
                          return (
                            <li key={index}>
                              <Anchor
                                href={menuItem.href}
                                dangerouslySetInnerHTML={{
                                  __html: menuItem.title,
                                }}
                              />
                            </li>
                          )
                      })}
                    <ul>
                      <li>
                        <div
                          style={{
                            position: 'relative',
                          }}
                        >
                          <button
                            className="language-selection-button"
                            onClick={languageOpen}
                            dangerouslySetInnerHTML={{
                              __html: app.language
                                .substring(0, 2)
                                .toUpperCase(),
                            }}
                          />

                          <button
                            className={`language-selection ${
                              isLanguageMenu ? 'language-selection-show' : ''
                            }`}
                          >
                            {app.languages.map((lang, index) => (
                              <div
                                key={index}
                                onClick={() => handleLanguageSelection(lang.id)}
                                className="stg-menu-language"
                              >
                                {lang.title}
                              </div>
                            ))}
                          </button>
                        </div>
                      </li>
                    </ul>
                  </ul>
                ) : (
                  <button className="stg-header-menu-button" onClick={openMenu}>
                    {!isMobileMenuOpen && (
                      <Icon size={'large'} name="icon-menu" />
                    )}
                  </button>
                )}
              </div>
            </Column>
          </Row>
        </Container>
      </header>
      {width < 860 && (
        <div
          onClick={handleMenuClose}
          className={`menu ${isMobileMenuOpen ? 'menu-show' : ''}`}
        >
          <div className="stg-mobile-menu">
            <Image className="stg-mobile-menu-logo" {...mobilelogo} alt="" />
            <button className="stg-mobile-menu-button" onClick={openMenu}>
              {isMobileMenuOpen ? (
                <Icon size={'xlarge'} name="clear" />
              ) : (
                <Icon size={'large'} name="icon-menu" />
              )}
            </button>
          </div>
          <div>
            <ul>
              <li>
                <Icon size="large" name="keyboard-arrow-right" />
                <Anchor style={{ textTransform: 'capitalize' }} href={homeUrl}>
                  {homeTitle}
                </Anchor>
              </li>
              {Array.isArray(menu) &&
                menu?.map((menuItem, index) => {
                  if (menuItem?.href === '#footer') {
                    return (
                      <li key={index} onClick={handlerRouterFooter}>
                        <Icon size="large" name="keyboard-arrow-right" />
                        <span>{menuItem?.title}</span>
                      </li>
                    )
                  } else
                    return (
                      <li key={index}>
                        <Icon size="large" name="keyboard-arrow-right" />
                        <Anchor
                          href={menuItem.href}
                          dangerouslySetInnerHTML={{ __html: menuItem.title }}
                        />
                      </li>
                    )
                })}
            </ul>
            <div className="stg-language">
              {app.languages.map((lang, index) => (
                <button
                  key={index}
                  onClick={() => handleLanguageSelection(lang.id)}
                  className="stg-mobile-menu-language"
                >
                  {lang.title}
                </button>
              ))}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  )
}

export default Header
